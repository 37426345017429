<!-- eslint-disable vue/valid-v-slot -->
<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      title="Ordem de Serviço"
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <div class="col-md-2 mb-2">
            <label>Status</label>
            <v-select
              v-model="statusFilter"
              :options="optionsStatusFilter"
              multiple
            />
          </div>
          <div class="col-md-2 mb-2">
            <label>Atendente</label>
            <v-select
              v-model="atendenteFilter"
              :options="optionsAtendenteFilter"
              multiple
            />
          </div>
          <div class="col-md-2 mb-2">
            <label>Quant. de Dias em Aberto</label>
            <v-select
              v-model="QDAFilter"
              :options="optionsQDAFilter"
              multiple
            />
          </div>
          <div class="col-md-2 mb-2">
            <label>Seguradora</label>
            <v-select
              v-model="seguradoraFilter"
              :options="optionsSeguradoraFilter"
              multiple
            />
          </div>
          <div class="col-md-2 mb-2">
            <label>Filtro por Revendas</label>
            <v-select
              v-model="revendaFilter"
              :options="optionsRevendaFilter"
              multiple
            />
          </div>
          <div class="col-md-2 mb-2">
            <label>Filtro por Linha</label>
            <v-select
              v-model="linhaFilter"
              :options="optionsLinhaFilter"
              multiple
            />
          </div>
          <div class="col-md-2 mb-2">
            <label>Reparo</label>
            <v-select
              v-model="reparoFilter"
              :options="optionsReparoFilter"
              multiple
            />
          </div>
          <div class="col-md-2 mb-2">
            <label>Filtro por Reclamação</label>
            <v-select
              v-model="selectedReclamacaoFilter"
              :options="optionsReclamacaoFilter"
            />
          </div>
          <b-button
            style="width: 150px; height: 45px; margin-top: 15px"
            variant="primary"
            @click="showModalFiltro=true, load=true"
          >
            Mais Filtros
          </b-button>
        </b-row>
      </div>
      <div class="m-2">

        <!-- Table Top -->
        <b-row
          class="mb-2"
        >
          <!-- Search -->
          <b-col
            cols="1"
          >
            <b-form-select
              v-model="perPage"
              label="text"
              :options="[10, 25, 50, 100]"
              size="sm"
            />
          </b-col>
          <b-col
            cols="1"
          >
            <b-badge
              class="p-1"
              pill
              variant="light-primary"
            >
              Total: {{ totalRows }}
            </b-badge>

          </b-col>
          <b-col
            lg="8"
            class="d-flex align-items-center justify-content-start"
          >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Pesquisar..."
              :options="[10, 15, 20]"
              type="search"
              size="sm"
            />
          </b-col>
          <b-col
            cols="2"
          >
            <b-button
              v-show="listFiltered.length > 0"
              variant="outline-warning"
              @click="exportFile"
            >
              Exportar excel
            </b-button>
          </b-col>

        </b-row>

      </div>
      <b-overlay
        :show="load"
        variant="transparent"
      >
        <b-table
          ref="refUserListTable"
          hover
          class="position-relative"
          :current-page="currentPage"
          :filter="searchQuery"
          :items="listFiltered"
          :per-page="perPage"
          responsive
          :fields="fields"
          show-empty
          empty-text="Sem dados!"
          @filtered="onFiltered"
          @row-clicked="onRowClicked"
        >
          <template #cell(id)="data">
            <a :href="'/os/view/' + data.item.id">{{ data.item.id }}</a>
          </template>
          <!-- Column: Actions -->
          <template #cell(notificacao)="data">
            <b-row class="justify-content-between align-items-center flex-nowrap">
              <b-col cols="auto">
                <feather-icon
                  v-b-tooltip.hover.top="'Informações'"
                  icon="MailIcon"
                  size="21"
                  class="text-secondary"
                  :badge="data.item.total_informacao ? data.item.total_informacao : '0'"
                  badge-classes="badge-secondary badge-glow"
                  @click="irParaOS(data.item.id)"
                />
              </b-col>
              <b-col cols="auto">
                <feather-icon
                  v-b-tooltip.hover.top="'Reagendamento Reparo'"
                  icon="CalendarIcon"
                  size="21"
                  class="text-secondary"
                  :badge="data.item.total_reagendamento_reparo ? data.item.total_reagendamento_reparo : '0'"
                  badge-classes="badge-secondary badge-glow"
                />
              </b-col>
              <b-col cols="auto">
                <feather-icon
                  v-b-tooltip.hover.top="'Reagendamento Visita'"
                  icon="CalendarIcon"
                  size="21"
                  class="text-secondary"
                  :badge="data.item.total_reagendamento_visita ? data.item.total_reagendamento_visita : '0'"
                  badge-classes="badge-secondary badge-glow"
                />
              </b-col>
              <b-col cols="auto">
                <div v-if="data.item.reclamacao">
                  <feather-icon
                    v-b-tooltip.hover.top="'Reclamação'"
                    icon="AlertTriangleIcon"
                    size="21"
                    class="text-secondary"
                    :badge="data.item.reclamacao ? data.item.reclamacao : '0'"
                    badge-classes="badge-danger badge-glow"
                  />
                </div>
              </b-col>
            </b-row>
          </template>

          <template #cell(data_abertura)="data">
            {{ americaDate(data.item.data_abertura) }}
          </template>
          <template #cell(data_agendamento)="data">
            <div v-if="data.item.data_agendamento != null">
              {{ americaDate(data.item.data_agendamento) }}
            </div>
          </template>
          <template #cell(previsao_reparo)="data">
            <div v-if="data.item.previsao_reparo != null">
              {{ americaDate(data.item.previsao_reparo) }}
            </div>
          </template>
          <template #cell(status)="data">
            <b-badge :variant="badgeVariantPerfil(data.item.status)">
              {{ data.item.status }}
            </b-badge>
          </template>
        </b-table>
      </b-overlay>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-modal
      ref="modalTransferirAtendente"
      ok-title="Transferir"
      cancel-title="Cancelar"
      cancel-variant="danger"
      title="Transferir Atendimento"
      no-close-on-backdropgti
      :ok-disabled="descricaoTransferenciaAtendente === ''"
      @ok="transferirAtendente"
    >
      <div>
        <label>Selecione o Atendente</label>
        <v-select
          v-model="selectedAtendente"
          :options="optionsAtendente"
        />
        <label>Descrição: </label>
        <b-form-textarea v-model="descricaoTransferenciaAtendente" />
      </div>
    </b-modal>
    <modal-fitro
      :show-modal="showModalFiltro"
      @modal-hidden="onModalHidden"
      @responsefiltro="responseFiltro"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination, BBadge,
  BButton,
  BFormTextarea, BFormSelect, VBTooltip, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { saveAs } from 'file-saver'
import modalFitro from '../componentesglobais/ModalFitro.vue'
import axios from '../../../axios-auth'

const ExcelJS = require('exceljs')

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BBadge,
    BFormSelect,
    vSelect,
    BButton,
    modalFitro,
    BFormTextarea,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      items: [],
      itemsReport: [],
      fields: [
        { key: 'id', label: 'id', class: 'text-center', sortable: 'true' },
        { key: 'notificacao', label: 'Notificação', class: 'text-center', sortable: 'true' },
        { key: 'data_abertura', label: 'Abertura', class: 'text-center', sortable: 'true' },
        { key: 'dias_em_aberto', label: 'QDA', class: 'text-center', sortable: 'true' },
        { key: 'data_agendamento', label: 'Data Agendamento', class: 'text-center', sortable: 'true' },
        { key: 'previsao_reparo', label: 'Previsao de Reparo', class: 'text-center', sortable: 'true' },
        { key: 'sinistro', label: 'sinistro', class: 'text-center', sortable: 'true' },
        { key: 'status', label: 'status', class: 'text-center', sortable: 'true' },
        { key: 'tipo_atendimento', label: 'tipo atendimento', class: 'text-center', sortable: 'true' },
        { key: 'atendente', label: 'Atendente', class: 'text-center', sortable: 'true' },
        { key: 'cliente', label: 'cliente', class: 'text-center', sortable: 'true' },
        { key: 'produto', label: 'produto', class: 'text-center', sortable: 'true' },
        { key: 'seguradora', label: 'seguradora', class: 'text-center', sortable: 'true' },
        { key: 'revenda', label: 'revenda', class: 'text-center', sortable: 'true' },
        { key: 'linha', label: 'linha', class: 'text-center', sortable: 'true' },
        { key: 'marca', label: 'marca', class: 'text-center', sortable: 'true' },
      ],
      sortBy: 'dias_em_aberto',
      perPage: 100,
      currentPage: 1,
      totalRows: 1,
      searchQuery: null,
      osId: null,
      optionsAtendente: [],
      selectedAtendente: [],
      selectedReclamacaoFilter: [],
      showModalFiltro: false,
      descricaoTransferenciaAtendente: '',
      motivoEventoOptions: [],
      motivoEventoID: 2,
      empresaID: null,
      atendenteFilter: [],
      statusFilter: [],
      revendaFilter: [],
      reparoFilter: [],
      seguradoraFilter: [],
      linhaFilter: [],
      tipoAtendimentoFilter: [],
      QDAFilter: [],
      reclamacaoFilter: [],
      optionsQDAFilter: ['Mais de 5 dias', 'Mais de 10 dias', 'Mais de 15 dias', 'Mais de 30 dias'],
      load: true,
    }
  },
  computed: {
    optionsAtendenteFilter() {
      const atendente = []
      this.items.map(item => {
        item.atendente ? atendente.push(item.atendente) : null
      })
      const itemsFiltered = [...new Set(atendente)]
      return itemsFiltered
    },
    optionsRevendaFilter() {
      const revenda = []
      this.items.map(item => {
        item.revenda ? revenda.push(item.revenda) : null
      })
      const itemsFiltered = [...new Set(revenda)]
      return itemsFiltered
    },
    optionsSeguradoraFilter() {
      const seguradora = []
      this.items.map(item => {
        item.seguradora ? seguradora.push(item.seguradora) : null
      })
      const itemsFiltered = [...new Set(seguradora)]
      return itemsFiltered
    },
    optionsStatusFilter() {
      this.status = ''
      const status = []
      this.items.map(item => {
        item.status ? status.push(item.status) : null
      })
      const itemsFiltered = [...new Set(status)]
      return itemsFiltered
    },
    optionsLinhaFilter() {
      const linha = []
      this.items.map(item => {
        item.linha ? linha.push(item.linha) : null
      })
      const itemsFiltered = [...new Set(linha)]
      return itemsFiltered
    },
    optionsReparoFilter() {
      const reparo = []
      this.items.map(item => {
        item.reparo ? reparo.push(item.total_reagendamento_reparo) : null
      })
      const itemsFiltered = [...new Set(reparo)]
      return itemsFiltered
    },
    optionsTipoAtendimentoFilter() {
      const tipoAtendimento = []
      this.items.map(item => {
        item.tipo_atendimento ? tipoAtendimento.push(item.tipo_atendimento) : null
      })
      const itemsFiltered = [...new Set(tipoAtendimento)]
      return itemsFiltered
    },
    optionsReclamacaoFilter() {
      const reclamacao = ['COM RECLAMACÃO', 'SEM RECLAMAÇÃO']
      return reclamacao
    },
    listFiltered() {
      const { items, revendaFilter: rF, linhaFilter: lF, QDAFilter: dF, seguradoraFilter: xF, atendenteFilter: aF, statusFilter: sF, selectedReclamacaoFilter: sRF } = this
      let list = items

      // Filtro por LInha
      if (lF.length > 0) {
        list = list.filter(item => lF.includes(item.linha))
      }
      // Filtro por Status
      if (sF.length > 0) {
        list = list.filter(item => sF.includes(item.status))
      }
      // Filtro por Atentende
      if (aF.length > 0) {
        list = list.filter(item => aF.includes(item.atendente))
      }
      // Filtro por Revenda
      if (rF.length > 0) {
        list = list.filter(item => rF.includes(item.revenda))
      }
      // Filtro por Seguradora
      if (xF.length > 0) {
        list = list.filter(item => xF.includes(item.seguradora))
      }
      // Filtro por dias em aberto
      if (dF.length > 0) {
        const qt = this.QDAFilter.length - 1
        const diasFiltro = parseInt(this.QDAFilter[qt].replace(/[^0-9]/g, ''))
        list = list.filter(item => this.diasVencidoCalc(item.data_abertura) >= diasFiltro)
      }
      // Filtro por Reclamação
      if (sRF && sRF.length > 0) {
        if (sRF === 'COM RECLAMACÃO') {
          list = list.filter(item => item.reclamacao > 0)
        } else {
          list = list.filter(item => item.reclamacao === 0)
        }
      }
      this.totalRows = list.length
      return list
    },
  },
  created() {
    this.empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
    this.getListOs(this.empresaID)
  },
  methods: {
    async exportFile() {
      const file = await this.getDataToExported()
      if (file) {
        saveAs(file)
      }
    },

    async getDataToExported() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Relatório')
      const itemsPlan = this.listFiltered

      worksheet.columns = [
        { header: 'ID', key: 'id' },
        { header: 'NOME', key: 'nome' },
        { header: 'ABERTURA', key: 'data_abertura' },
        { header: 'DIAS VENCIDOS', key: 'dias_em_aberto' },
        { header: 'DATA AGENDAMENTO', key: 'data_agendamento' },
        { header: 'PREVISÃO REPARO', key: 'previsao_reparo' },
        { header: 'SINISTRO', key: 'sinistro' },
        { header: 'STATUS', key: 'status' },
        { header: 'TIPO ATENDIMENTO', key: 'tipo_atendimento' },
        { header: 'Atendente', key: 'atendente' },
        { header: 'CLIENTE', key: 'cliente' },
        { header: 'PRODUTO', key: 'produto' },
        { header: 'SEGURADORA', key: 'seguradora' },
        { header: 'REVENDA', key: 'revenda' },
        { header: 'LINHA', key: 'linha' },
        { header: 'MARCA', key: 'marca' },
      ]

      itemsPlan.map(item => {
        worksheet.addRow([
          item.id,
          item.nome,
          item.data_abertura ? this.formatTimezone(item.data_abertura) : null,
          item.dias_em_aberto,
          item.data_agendamento ? this.formatTimezone(item.data_agendamento) : null,
          item.previsao_reparo,
          item.sinistro,
          item.status,
          item.tipo_atendimento,
          item.atendente,
          item.cliente,
          item.produto,
          item.seguradora,
          item.revenda,
          item.linha,
          item.marca,
        ])
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório Preventivo', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
    onRowClicked(item) {
      this.$router.push({ name: 'os-view', params: { id: item.id } })
    },
    irParaOS(id) {
      this.$router.push({ name: 'os-view', params: { id } })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getListOs(empresaID) {
      this.items = []
      const query = {
        data_final: '2024-12-01',
        data_inicial: '2024-04-02',
      }
      axios.get(`api/ordem_servico/lista/${empresaID}`, { params: query })
        .then(res => {
          this.load = false
          this.items = res.data.dados
          this.totalRows = this.items.length
        })
    },
    async openModalTransferirAtendente(id) {
      this.osId = id
      await this.getAtendentes(this.empresaID)
      await this.getMotivoEventos()
      this.$refs.modalTransferirAtendente.show()
    },
    diasVencidoCalc(data) {
      const dataAtual = new Date()
      const dataAbertura = new Date(data)
      const diferencaEmDias = Math.floor((dataAtual - dataAbertura) / (1000 * 60 * 60 * 24))
      return diferencaEmDias
    },
    getAtendentes(empresaId) {
      this.optionsAtendente = []
      axios.get(`api/empresas/atendentes/${empresaId}`)
        .then(response => {
          response.data.dados.map(item => {
            this.optionsAtendente.push({
              label: item.name,
              id: item.id,
            })
          })
        })
    },
    getMotivoEventos() {
      this.motivoEventoOptions = []
      axios
        .get('api/motivo_evento/')
        .then(res => {
          res.data.dados.map(item => {
            this.motivoEventoOptions.push({
              label: item.nome,
              id: item.id,
            })
          })
        })
    },
    badgeVariantPerfil(perfil) {
      const colors = {
        AGENDAR: 'light-warning',
        'EM ATENDIMENTO': 'light-primary',
        'AGUARDANDO APROVAÇÃO': 'light-danger',
        'EM REPARO': 'light-secondary',
        'REPARO CONCLUÍDO': 'light-success',
        'REPARO ENTREGUE': 'light-danger',
      }
      return colors[perfil] || 'light-secondary'
    },
    transferirAtendente() {
      const body = {
        atendente_id: this.selectedAtendente.id,
        motivo_evento_id: this.motivoEventoID,
        descricao: this.descricaoTransferenciaAtendente,
      }

      axios.put(`api/ordem_servico/atualizar/atendente/${this.osId}`, body)
        .then(() => {
          this.clearTransferirAtendente()
          this.getListOs(this.empresaID)
          this.$swal({
            title: 'Atendente transferido!',
            icon: 'success',
            text: 'Atendente transferido!',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(error => {
          this.clearTransferirAtendente()
          if (error.response) {
            this.$swal({
              title: 'ERRO!',
              icon: 'error',
              text: `Ocorreu um erro: ${error.response.data.mensagem} `,
              customClass: {
                confimButton: 'btn btn-danger',
              },
            })
          }
        })
    },
    clearTransferirAtendente() {
      this.selectedAtendente = []
      this.osId = null
      this.descricaoTransferenciaAtendente = ''
    },
    onModalHidden() {
      this.showModalFiltro = false
    },
    responseFiltro(item) {
      this.load = false
      this.items = item
      this.totalRows = item.length
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
