<template>
  <div>
    <!-- Stats Card Vertical -->
    <b-row class="match-height">
      <b-col
        xl="4"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="danger"
          icon="AlertTriangleIcon"
          :statistic="totalizadores[0].total"
          statistic-title="Total Geral de reclamações"
          class="card-totais-atendimento-status"
        />
      </b-col>
      <b-col
        xl="2"
        md="2"
        sm="6"
      >
        <statistic-card-vertical
          color="primary"
          icon="AlertCircleIcon"
          :statistic="totalizadores[0].visita_nao_cumprida"
          statistic-title="Visita não cumprida"
          class="card-totais-atendimento-status"
        />
      </b-col>
      <b-col
        xl="2"
        md="2"
        sm="6"
      >
        <statistic-card-vertical
          color="warning"
          icon="CalendarIcon"
          :statistic="totalizadores[0].demora_no_reparo"
          statistic-title="Demora no reparo"
          class="card-totais-atendimento-status"
        />
      </b-col>
      <b-col
        xl="2"
        md="2"
        sm="6"
      >
        <statistic-card-vertical
          color="secondary"
          icon="AlertOctagonIcon"
          :statistic="totalizadores[0].mau_atendimento"
          statistic-title="Mau Atendimento"
          class="card-totais-atendimento-status"
        />
      </b-col>
      <b-col
        xl="2"
        md="2"
        sm="6"
      >
        <statistic-card-vertical
          color="warning"
          icon="MessageSquareIcon"
          :statistic="totalizadores[0].outros"
          statistic-title="Outros"
          class="card-totais-atendimento-status"
        />
      </b-col>

    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import { kFormatter } from '@core/utils/filter'
import listDirecionadorOs from '@/views/direcionador/dashboard/components/ListDirecionadorOs.vue'
import axios from '../../../../../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
  },
  data() {
    return {
      totalizadores: [],
    }
  },
  created() {
    const empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
    this.getTotalizadoresOs(empresaID)
  },
  methods: {
    enviarStatus(status) {
      this.$emit('status-enviado', status)
    },
    kFormatter,
    getTotalizadoresOs(id) {
      axios.get(`api/empresas/totalizadores/reclamacao/${id}`)
        .then(res => {
          this.totalizadores = res.data.dados
        })
    },
    selectCard(status) {
      listDirecionadorOs.methods.incrementCounter()
      this.$ls.set('status', status)
    },
    cardColor(status) {
      const colors = {
        AGENDAR: 'warning',
        'EM ATENDIMENTO': 'primary',
        'AGUARDANDO APROVAÇÃO': 'dark',
        'EM REPARO': 'secondary',
        'REPARO CONCLUÍDO': 'success',
        'REPARO ENTREGUE': 'primary',
        TROCA: 'info',
        NEGADO: 'danger',
      }
      return colors[status] || 'primary'
    },
    cardIcon(status) {
      const icons = {
        AGENDAR: 'CalendarIcon',
        'EM ATENDIMENTO': 'MessageSquareIcon',
        'AGUARDANDO APROVAÇÃO': 'ShoppingBagIcon',
        'EM REPARO': 'ToolIcon',
        'REPARO CONCLUÍDO': 'AwardIcon',
        'REPARO ENTREGUE': 'TruckIcon',
        TROCA: 'RefreshCcwIcon',
        NEGADO: 'ThumbsDownIcon',
      }
      return icons[status] || 'QuestionMarkIcon'
    },
  },
}
</script>
<style>
.card-totais-atendimento-status:hover {
  background-color: #5e9fe7;
  cursor: pointer;
}
</style>
