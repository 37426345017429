<template>
  <div>
    <b-row>
      <b-col md="4">
        <card-totais :items="items" />
      </b-col>
      <b-col
        md="8"
      >
        <statistics-direcionador :data="statisticsItems" />
      </b-col>
      <b-col
        md="12"
      >
        <card-totais-reclamacao-status />
      </b-col>
    </b-row>
    <section>
      <card-list-os />
    </section>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import CardTotais from './cards/CardTotaisSeguradora.vue'
import statisticsDirecionador from '../../dashboard/cards/CardStatistics.vue'
import CardListOs from '../../relatorios/OrdemServico.vue'
import CardTotaisReclamacaoStatus from './cards/TotaisReclamacoesStatus.vue'
import axios from '../../../../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    statisticsDirecionador,
    CardTotais,
    CardListOs,
    CardTotaisReclamacaoStatus,
  },
  data() {
    return {
      statisticsItems: [
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '',
          subtitle: 'CMR',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-warning',
          title: '',
          subtitle: 'CMG',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'CalendarIcon',
          color: 'light-primary',
          title: ' Dias',
          subtitle: 'TAT',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'RefreshCcwIcon',
          color: 'light-info',
          title: '%',
          subtitle: 'PT',
          customClass: '',
        },
        {
          icon: 'AlertTriangleIcon',
          color: 'light-danger',
          title: '%',
          subtitle: 'REC',
          customClass: '',
        },
      ],
      items: [],
      empresaID: null,
    }
  },
  created() {
    this.empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
    axios
      .get(`api/empresas/estatisticas/geral/${this.empresaID}`)
      .then(res => {
        this.statisticsItems[0].title = `R$ ${res.data.dados.cmr}`
        this.statisticsItems[1].title = `R$ ${res.data.dados.cmg}`
        this.statisticsItems[3].title = `${res.data.dados.pt}%`
        this.statisticsItems[2].title = `${res.data.dados.tat} Dias`
        this.statisticsItems[4].title = `${res.data.dados.rec}%`
      })
    this.getOS()
  },
  methods: {
    getOS() {
      const empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
      this.items = []
      axios.get(`api/ordem_servico/lista/${empresaID}`)
        .then(res => {
          this.items = res.data.dados
        })
    },
  },
}
</script>
