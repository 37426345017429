<template>
  <b-card class="card-congratulation-medal">
    <h5>Olá 🎉 {{ userName }}!</h5>
    <h3 class="mb-75 mt-4">
      <b-link> Os com Reclamações</b-link>
    </h3>
    <b-button
      id="toggle-btn"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      style="margin-top: 50px;margin-bottom: 10px;"
      class="mb-1"
      variant="primary"
    >
      <feather-icon
        icon="EyeIcon"
        class="mr-50"
      />
      <span class="align-middle">Ver OS</span>
    </b-button>
    <b-img
      :src="require('@/assets/images/illustration/badge.svg')"
      class="congratulation-medal"
      alt="Medal Pic"
    />
  </b-card>
</template>

<script>
import {
  BCard, BLink, BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BLink,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userName: localStorage.getItem('userName'),
    }
  },
  methods: {
    countTotais() {
      let totais = 0
      this.items.forEach(item => {
        if (item.status === 'AGUARDANDO ANÁLISE') {
          totais++
        }
      })
      return totais
    },
  },
}
</script>
